import Link from 'next/link';
import React from "react";
import Image from 'next/image';

export async function getStaticProps(context) {
    return {
        props: {}, // will be passed to the page component as props
    }
}

export default function Sec2() {

    return (
        <>
            <div className="inner-container sec-2">
                <div className="container">
                    <div className="inner-header-wrapper">
                        <div className="row">
                            <div className="col-md-6">
                                <Image className="pull-right" src="/images/sec-img.png"
                                    layout={"responsive"}
                                    width="504" height="386"
                                    alt="Couple filling tax forms"
                                    style={{maxWidth: "100%"}}/>
                            </div>
                            <div className="col-md-6">
                                <h2>You can easily extend your taxes from wherever you can access the internet</h2>
                                <p>By working with an authorized E-file provider, 
                                    you can have confidence in the security of your data, 
                                    and the peace of mind that your federal extension is 
                                    submitted immediately, for a modest fee. Answer a few 
                                    questions and you can elect to file your extension 
                                    electronically, at your convenience, or we will show 
                                    you how you can submit it for free!</p>
                                <small>
                                    <Link
                                        href="/faq" prefetch={false}>If you need more help, please read our FAQ</Link>.</small>
                            </div>                        
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
